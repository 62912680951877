import { ApiModuleBase } from '../ApiModuleBase.js'

export class Rates extends ApiModuleBase {
    static get namespace () {
        return 'rates'
    }

    static get urlNamespace () {
        return 'rates'
    }

    getActualRates (accountId = null) {
        return this.doGetRequest(`/${this.ns}/list`, { accountId })
    }

    getRatesForChart (currencyPair, accountId, days) {
        return this.doGetRequest(`/${this.ns}`, { currencyPair, accountId, days })
    }
}
