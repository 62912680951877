<template>
    <div>
        <div
            v-show="!confirm"
            :class="{ 'text-right': (align === 'right'), 'text-left': (align === 'left'), 'flex-grow-1 whitespace-nowrap': true }"
        >
            <slot></slot>
            <b-button
                variant="primary"
                size="sm"
                @click.prevent="$emit('edit')"
                v-if="!hideEdit"
            >
                <b-icon-pencil-square />
                <span v-if="withText">
                    {{editText}}
                </span>
            </b-button>
            <b-button
                variant="danger"
                size="sm"
                @click.prevent="confirm = true"
                :class="{ 'ml-2': !hideEdit }"
                v-if="!hideDelete"
            >
                <b-icon-trash />
                <span v-if="withText">
                    Smazat
                </span>
            </b-button>
        </div>
        <div
            v-show="confirm"
            :class="{ 'text-right': (align === 'right'), 'text-left': (align === 'left'), 'flex-grow-1 whitespace-nowrap': true }"
        >
            <b-button
                variant="primary"
                size="sm"
                @click.prevent="$emit('remove')"
            >
                <b-icon-check />
            </b-button>
            <b-button
                variant="danger"
                size="sm"
                @click.prevent="confirm = false"
                class="ml-2"
            >
                <b-icon-x />
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'list-actions-edit-delete',
    props: {
        withText: {
            type: Boolean,
            required: false,
            default: false
        },
        align: {
            type: String,
            required: false,
            default: 'right'
        },
        hideEdit: {
            type: Boolean,
            required: false,
            default: false
        },
        hideDelete: {
            type: Boolean,
            required: false,
            default: false
        },
        editText: {
            type: String,
            required: false,
            default: 'Upravit'
        }
    },
    data () {
        return {
            confirm: false
        }
    }
}
</script>

<style lang="scss">
    .whitespace-nowrap {
        white-space: nowrap;
    }
</style>
