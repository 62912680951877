import Router from 'vue-router'
import ComponentLoader from '../../Components/ComponentLoader.vue'
import ComponentError from '../../Components/ComponentError.vue'
import { HeadRequestGuard } from './HeadRequestGuard.js'
const loaderSettings = {
    loading: ComponentLoader,
    error: ComponentError,
    delay: 10,
    timeout: 30 * 1000
}

const createRouter = (appGuard = null) => {
    return new Router({
        mode: 'history',
        base: '/',
        routes: [
            {
                path: '',
                name: 'Index',
                component: () => import(/* webpackChunkName: "index" */ '../../Views/Index.vue'),
                ...loaderSettings,
                beforeEnter: appGuard.beforeEnterLogin()
            },
            {
                path: '/swagger',
                name: 'Api',
                component: () => import(/* webpackChunkName: "api" */ '../../Views/Api/ApiPage.vue'),
                ...loaderSettings
            },
            {
                path: '/accounts',
                name: 'AccountSelect',
                component: () => import(/* webpackChunkName: "accountSelect" */ '../../Views/AccountSelect/AccountSelectPage.vue'),
                ...loaderSettings,
                beforeEnter: appGuard.beforeEnter()
            },
            {
                path: '/rates',
                component: () => import(/* webpackChunkName: "rates" */ '../../Views/Rates/RatesPage.vue'),
                ...loaderSettings,
                beforeEnter: appGuard.beforeEnter(),
                children: [
                    {
                        path: '',
                        name: 'Rates',
                        component () {
                            return {
                                component: import(/* webpackChunkName: "ratesTable" */ '../../Views/Rates/Views/RatesTablePage.vue'),
                                ...loaderSettings
                            }
                        }
                    },
                    {
                        path: 'dashboard',
                        name: 'RatesDashboard',
                        component () {
                            return {
                                component: import(/* webpackChunkName: "ratesDashboard" */ '../../Views/Rates/Views/RatesDashboardPage.vue'),
                                ...loaderSettings
                            }
                        }
                    }
                ]
            }
            /* {
                path: '/provisions',
                name: 'ProvisionsPage',
                component: () => import(/* webpackChunkName: "provisions" *//* '../../Views/ProvisionsPage/ProvisionsPage.vue'),
            /*    ...loaderSettings,
                beforeEnter: appGuard.beforeEnter(),
                children: [
                    {
                        path: '',
                        name: 'ProvisionsSummaryPage',
                        props (route) {
                            return { ...route.params, ...route.query }
                        },
                        component () {
                            return {
                                component: import(/* webpackChunkName: "provisionsSummary" */ /* '../../Views/ProvisionsPage/Views/Summary/SummaryPage.vue'),
            /*                    ...loaderSettings
                            }
                        }
                    }, */
        ]
    })
}

export class RouterFactory {
    constructor (appSettings) {
        this.appSettings = appSettings
        this.appGuard = new HeadRequestGuard(this.appSettings.APP_GUARD_URL, { name: 'Index' })
        this._router = createRouter(this.appGuard)
    }

    get router () {
        return this._router
    }
}
