<template>
    <footer class="main-footer">
        <div class="bg-gradient-cyrrus-black-semiblack pt-4">
            <div class="container mt-3">
                <div class="row justify-content-between align-items-start align-content-start">
                    <div class="col-12 col-md-8 mb-4">
                        <h3 class="text-cyrrus-yellow">{{$t('footer.claim')}}</h3>
                        <p class="text-bigger mt-4 mb-2">
                            <span data-icon="mdi:phone" class="iconify mr-2 text-cyrrus-orange"></span> <a class="text-white" href="tel:+420226258226">+420 <strong>226 258 226</strong></a>
                        </p>
                        <p class="text-bigger">
                            <span data-icon="mdi:at" class="iconify mr-2 text-cyrrus-orange"></span> <a class="text-white" href="mailto:fxinfo@cyrrus.cz">fxinfo@cyrrus.cz</a>
                        </p>
                    </div>

                    <div class="col-12 col-md-4 col-lg-3">
                        <img  src="/img/CFX_logotype_bila.svg" alt="CYRRUS FX" class="img img-fluid">
                    </div>
                </div>
            </div>
            <div class="border-top-2 py-3 text-center text-cyrrus-gray-dark text-md-right border-cyrrus-gray-dark-03 mt-5">
                <div class="container">
                    <span>© 2021 CYRRUS FX, a.s.</span>
                    <span class="mx-2">|</span>
                    <span class="credits-legal-info">
                        <a class="text-cyrrus-gray-dark" :href="$t('footer.gdprLink')" :title="$t('footer.gdpr')">{{$t('footer.gdpr')}}</a>
                    </span>
                    <span class="mx-2">|</span>
                    <router-link class="mr-3 text-cyrrus-gray-dark" :to="{ name: 'Api' }">{{$t('apiLinkText')}}</router-link>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>
