<template>
    <div id="app" class="main-flex">
        <header class="main-header shadow-sm">
            <top-bar />
            <app-navbar>
                <b-nav-item-dropdown :text="(selectedAccount) ? selectedAccount.client_description : $t('navigation.changeAccount')">
                    <b-dropdown-item
                        v-for="item in accounts"
                        :key="item.account_id"
                        @click.prevent="onSelectAccount(item)"
                    >
                        {{item.client_description}}
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <li class="nav-item">
                    <a href="javascript:void(0);" @click.prevent="onLogout" target="_self" class="nav-link">
                        <span class="iconify mr-1 text-danger" data-icon="mdi:account-lock"></span> {{$t('navigation.logout')}}
                    </a>
                </li>
            </app-navbar>
        </header>

        <div class="main-content bg-gradient-cyrrus-gray-dark">
            <transition
                enter-active-class="animated fadeIn faster"
                leave-active-class="animated fadeOut faster"
                mode="out-in"
            >
                <router-view
                    :selected-account="selectedAccount"
                ></router-view>
            </transition>
        </div>

        <app-footer/>
    </div>
</template>

<script>
import AppFooter from './Components/Layout/Footer.vue'
import TopBar from './Components/Layout/TopBar.vue'
import AppNavbar from './Components/Layout/AppNavbar.vue'

export default {
    name: 'App',
    components: {
        AppFooter,
        TopBar,
        AppNavbar
    },
    computed: {
        accounts () {
            return this.$user.accessList || []
        },
        selectedAccount: {
            get () {
                return this.$store.state.selectedAccount
            },
            set (account) {
                this.$store.commit('selectAccount', account)
            }
        }
    },
    methods: {
        onSelectAccount (account) {
            this.selectedAccount = account
            if (this.$router.currentRoute.name !== 'Rates') {
                this.$router.push({ name: 'Rates' })
            }
        },
        async onLogout () {
            try {
                await this.$api.auth.logout()
            } catch (error) {
                console.error(error)
            }
            this.$user.onLoggedOut()
            this.$notify.success(this.$t('navigation.loggedOutMessage'))
            this.$router.replace({ name: 'Index' })
        }
    }
}
</script>

<style lang="scss">
@import './scss/app.scss';

.form-sm .custom-select {
    height: 2.5rem;
    padding: .5rem;
    border-radius: 0;
}
</style>
