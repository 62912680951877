import { Bootstrap } from './Bootstrap.js'
const appElement = document.getElementById('app')

if (
    appElement instanceof Element
) {
    const appBootstrap = new Bootstrap()
    appBootstrap.initialize()
    appBootstrap.render(appElement)
}
