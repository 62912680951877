<template>
    <div class="list-group">
        <div
            v-for="(item, index) in items"
            :key="`item-${index}-${item[itemTitleProp]}`"
            class="list-group-item d-flex justify-content-between align-items-center"
        >
            <div>{{item[itemTitleProp]}}</div>
            <list-actions-edit-delete
                v-if="canEdit || canRemove"
                :hide-edit="!canEdit"
                :hide-remove="!canRemove"
                @edit="onEdit(item)"
                @remove="onRemove(item)"
            />
        </div>
    </div>
</template>

<script>
import ListActionsEditDelete from './ListActionsEditDelete.vue'

export default {
    name: 'ListGroup',
    components: {
        ListActionsEditDelete
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        itemTitleProp: {
            type: String,
            required: false,
            default: 'title'
        },
        canRemove: {
            type: Boolean,
            required: false,
            default: true
        },
        canEdit: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    methods: {
        onRemove (item) {
            if (this.canRemove) {
                this.$emit('remove', item)
            }
        },
        onEdit (item) {
            if (this.canEdit) {
                this.$emit('edit', item)
            }
        }
    }
}
</script>
