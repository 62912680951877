import { ApiModuleBase } from '../ApiModuleBase.js'

export class Dashboard extends ApiModuleBase {
    static get namespace () {
        return 'dashboard'
    }

    static get urlNamespace () {
        return 'dashboard'
    }

    getDashboards (accountId) {
        return this.doGetRequest(`/${this.ns}`, { accountId })
    }
}
