<template>
    <div class="navbar-top-bar">
        <div class="container">
            <div class="navbar-top-bar-row">
                <div class="navbar-top-bar-logo">
                    <a href="https://www.cyrrus-fx.cz/" class="site-brand" title="CYRRUS FX" target="_self">
                        <img src="/img/logotype-cyrrus-fx.svg" width="215" height="35" alt="CYRRUS FX" class="site-brand-logo"/>
                    </a>
                </div>

                <div class="navbar-top-bar-addons">
                    <div class="d-flex justify-content-end align-items-center align-content-center">
                        <div class="mr-3">
                            <span class="iconify text-cyrrus-orange" data-icon="mdi:phone"></span>
                            <a class="text-black text-decoration-none" href="tel:+420226258226" target="_self">
                                +420 <strong>226 258 226</strong>
                            </a>
                        </div>
                        <a class="d-none d-lg-inline-block btn btn-sm btn-cyrrus-orange mr-3"
                            :href="$t('navigation.onlineContractLink')"
                            target="_self"
                            :title="$t('navigation.onlineContract')"
                        >
                            <span class="iconify" data-icon="mdi:pen"></span> {{$t('navigation.onlineContract')}}
                        </a>
                        <a class="d-none d-lg-inline-block btn btn-sm btn-cyrrus-green mr-3"
                            :href="$t('navigation.ibLoginLink')"
                            target="_self"
                            :title="$t('navigation.ibLogin')"
                        >
                            <span class="iconify" data-icon="mdi:account"></span> {{$t('navigation.ibLogin')}}
                        </a>
                        <div class="lang-switcher">
                            <a href="javascript:void(0);" @click.prevent="onToggleLang" target="_self" :title="$t('navigation.switchLang')" class="lang-switcher-link">
                                <img :src="$t('navigation.flag')" class="img img-fluid d-inline-block" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopBar',
    methods: {
        onToggleLang () {
            this.$store.commit('setLang', ((this.$store.state.lang === 'cz') ? 'en' : 'cz'))
            this.$i18n.locale = `${this.$store.state.lang}`
        }
    }
}
</script>
