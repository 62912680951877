import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// import { DTOClass } from '../../DTO/DTOClass'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        lang: 'cz',
        selectedAccount: null
    },
    mutations: {
        setLang (state, lang) {
            state.lang = lang
        },
        selectAccount (state, accountId) {
            state.selectedAccount = accountId
        }
    },
    actions: {
    },
    modules: {
    },
    plugins: [
        createPersistedState({
            key: 'cfxRatesApp',
            storage: window.sessionStorage,
            overwrite: true
        })
    ]
})
