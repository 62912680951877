<template>
    <div class="__loader-wrapper">
        <div class="__loader">
            {{$t('loading')}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ComponentLoader'
}
</script>

<style lang="scss">
    @import '../scss/_settings.scss';
    @import '../../node_modules/bootstrap/scss/_mixins.scss';
    @import '../../node_modules/bootstrap/scss/_functions.scss';
    @import '../../node_modules/bootstrap/scss/_variables.scss';

    .__loader-wrapper {
        display: block;
        position: relative;
        width: 100%;
        height: 4em;
        overflow: hidden;
    }

    .__loader, .__loader:after {
        border-radius: 50%;
        width: 5em;
        height: 5em;
    }

    .__loader {
        left: calc(50% - 2.5em);
        top: calc(50% - 2.5em);
        font-size: 10px;
        position: absolute;
        text-indent: -9999em;
        border-top: .5em solid rgba($cyrrusYellowA, 0.2);
        border-right: .5em solid rgba($cyrrusYellowA, 0.2);
        border-bottom: .5em solid rgba($cyrrusYellowA, 0.2);
        border-left: .5em solid $cyrrusYellowA;
        transform: translateZ(0);
        animation: load8 1.1s infinite linear;
    }

    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>
