<template>
    <div class="alert alert-danger text-center">
        {{$t('componentError')}}
    </div>
</template>

<script>
export default {
    name: 'ComponentError'
}
</script>
