<template>
    <div class="container px-0">
        <b-navbar toggleable="lg" type="cyrrus" class="position-static">
            <b-navbar-toggle
                target="navComponent-mainNavigation"
                class="navbar-toggler ml-auto"
            >
                <span class="navbar-ham-wrapper">
                    <svg class="navbar-ham navbar-ham-rotate" viewBox="0 0 100 100">
                        <path class="ham-line ham-line-top" d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path>
                        <path class="ham-line ham-line-middle" d="m 30,50 h 40"></path>
                        <path class="ham-line ham-line-bottom" d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path>
                    </svg>
                </span>
                <span class="navbar-toggler-text">Menu</span>
            </b-navbar-toggle>
            <b-collapse id="navComponent-mainNavigation" is-nav>
                <b-navbar-nav v-if="isLoggedIn" class="ml-auto">
                    <slot></slot>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
export default {
    name: 'AppNavbar',
    computed: {
        isLoggedIn () {
            return this.$user.isLoggedIn
        }
    }
}
</script>
